<template>
  <p class="message"><slot /></p>
</template>

<style scoped>
  .message {
    font-size: 40px;
    text-align: center;
    color: #909399;
    margin-top: 50px;
  }
</style>
